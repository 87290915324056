<template>
  <div class="sellerfaq">
    <!-- 购买流程 -->
    <!-- <ul class="shopStep">
      <li v-for="(shopItem, index) in stepList"
          :key="index">
        <div class="shopSvg">
          <svg width="50px"
               height="50px"
               viewBox="0 0 16 16"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  :d="pathItem.pathD"
                  v-for="(pathItem, index) in shopItem.path"
                  :key="index"></path>
          </svg>
        </div>
        <div class="shopText">{{shopItem.name}}</div>
        <div class="shopImg"
             v-if="shopItem.bool">
          <img src="../../assets/img/step.png"
               alt="" />
        </div>
      </li>
    </ul> -->
    <!-- 常见问题 -->
    <div class="container">
      <h4>1. Policy Overview</h4>
      <p>Welcome to crediscounts.com. This Privacy Policy is provided to inform you of our policies and practices regarding the collection, use, and disclosure of information about you.</p>
      <p>Crediscounts.com (“Crediscounts”, “we,” “us” or “our”) is committed to respecting the privacy rights and concerns of all users of the Crediscounts.com website where this Privacy Policy is posted (the “Site”) . As such, we have established and implemented this Privacy Policy to inform visitors to the Site and users of the Crediscounts Features how we use and protect the information we collect through the Site and Crediscounts Features.</p>
      <p>y visiting the Site or using any of the Crediscounts Features, you consent to Crediscounts’s collection, use, storage, deletion and disclosure of information relating to you as set forth in this Privacy Policy. This Privacy Policy is effective as of the date set forth above and is only applicable to the Site and not to any other website that you may be able to access from the Site which may have its own data collection and use practices and policies.</p>
      <h4>2. What information do we collect?</h4>
      <h6>PERSONAL INFORMATION</h6>
      <p>Personal information means information that can be used to identify and contact you. You need to submit personal information to access certain aspects of the Site and the Crediscounts Features.</p>
      <p>We collect personal information when you participate in feedback forms, questionnaires, contests or sweepstakes; purchase a product through the Crediscounts Features; receive customer or technical support;utilizing the Site and Crediscounts Features;registering products through the Site.</p>
      <p>We receive thousands of promotions from Amazon sellers every day. If you often start your shopping trip on Amazon, please follow us at crediscounts.com and you will find amazing offers on our platform</p>
      <p>
        The informaiton we collect including：<br>
        <em>name</em><br>
        <em>address</em><br>
        <em>email address</em><br>
        <em>telephone number</em><br>
        <em>products you purchased</em><br>
        <em>where you purchased your products</em><br>
      </p>
      <h6>COOKIES</h6>
      <p>“Cookies” are small bits of electronic information that a website sends to a visitor’s browser and are stored on the visitor’s hard drive. Like many websites, we employ cookies in certain areas of the Site to allow us to provide information to make your online experience more convenient. For example, when you return to the Site after logging in, cookies provide information to the Site so that the Site will remember who you are on subsequent visits, speeding up or enhancing your experience of the Crediscounts Features by, for instance, tailoring content to you. Cookies also prevent items placed in a shopping basket from disappearing by the time you checkout.</p>
      <h6>LOG INFORMATION</h6>
      <p>Our servers automatically record information that your browser sends whenever you visit the Site. This information includes your Internet Protocol address, your browser type and version, the search engine you used to find the Crediscounts Features, if any, which Crediscounts Features you use and when and how long you use them. We use this information to monitor and analyze how users use the Crediscounts Features, to provide customer service and to maintain and improve the Crediscounts Features.</p>
      <h6>CHILDREN’S PRIVACY</h6>
      <p>We do not knowingly collect personal information from children under 13. We take children’s privacy seriously, and encourage parents to play an active role in their children’s online experience at all times.</p>
      <h4>3. How do we use the information</h4>
      <p>The personal information you provide to us will allow us to fulfill any product or service orders; alert you of new products or services, features, or enhancements; handle your customer service questions or issues; administer promotions, contests and sweepstakes; and notify you of promotions, updates, or special offers that we think may interest you. We also use your email address and phone number as part of the Crediscounts Features in sending you messages about the Crediscounts Features and other general announcements.</p>
      <p>We keep track of your interactions with us, including but not limited to your activity within the Crediscounts Features. We may use personal information that you provide to us to send messages to you in response to your request to perform an activity in connection with your use of any of the Crediscounts Features. We may also use your personal information to contact you when necessary.</p>
      <p>We may use your personal information with non-personal information that we have collected in a manner such that the end-product does not personally identify you or any other user of the Crediscounts Features. We may make your personal information non-personally identifiable by either combining it with information about other users (aggregating your personal information with information about other users), or by removing characteristics (such as your name or email address) that make the information personally identifiable. This process is known as de-personalizing your information.</p>
      <p>You grant us a royalty-free, worldwide, perpetual, irrevocable and fully transferable right and license to use your personal information in connection with the creation and development of analytical and statistical analysis tools relating to the use of the customer data we collect in providing the Crediscounts Features (the Analytical Data). We are expressly authorized to make any commercial use of the Analytical Data, including without limitation, sharing the Analytical Data with third parties, provided that we do not sell, trade, or otherwise transfer from us any part of the Analytical Data that personally identifies any Crediscounts Features users.</p>
      <h4>4. Will we share your personal information with third parties?</h4>
      <h6>SHARING OF INFORMATION</h6>
      <p>We use your personal information to complete transactions, respond to your requests, deliver products and services you request, and send communications to you about promotions, updates, or special offers (which may be offered by Crediscounts or one of its business partners) that may be of interest to you. We may also use your personal information to provide analyses of our users in the aggregate (including Analytical Data subject to the terms of this Privacy Policy) to prospective partners and other third parties. In addition, we will share the personal information we collect from you under the following circumstances:</p>
      <h6>PROTECTION OF RIGHTS</h6>
      <p>We will share personal information if we have a good faith belief that (i) access, use, preservation or disclosure of such information is reasonably necessary to satisfy any applicable law, regulation, legal process, such as a court order or subpoena, or a request by law enforcement or governmental authorities, (ii) such action is appropriate to enforce our Terms of Use for the Crediscounts Features, including any investigation of potential violations thereof, (iii) such action is necessary to detect, prevent, or otherwise address fraud, security or technical issues associated with the Crediscounts Features, or (iv) such action is appropriate to protect the rights, property or safety of Crediscounts, its employees, users of the Crediscounts Features or others.</p>
      <h6>ASSET TRANSFERS</h6>
      <p>If we become involved in a merger, acquisition or other transaction involving the sale of some or all of Crediscounts’s assets, user information, including personal information collected from you through your use of the Crediscounts Features, could be included in the transferred assets. Should such an event occur, we will use reasonable means to notify you through email and/or a prominent notice on the Site.</p>
      <h6>SERVICE PROVIDERS</h6>
      <p>We may share your personal information with service providers that we engage for the purpose of processing information on our and your behalf. For example, we may partner with other companies to process payments for the Crediscounts Features. Additionally, we may partner with other companies to process, analyze, and/or store data, including, but not limited to, Analytical Data. While providing services for us, these companies may access your personal information. To the extent applicable, we require these entities to comply with this Privacy Policy and appropriate confidentiality and security measures.</p>
      <h6>ADDITIONAL INFORMATION YOU SHOULD KNOW ABOUT THIRD PARTIES</h6>
      <p>This Privacy Policy does not cover the information practices of third party websites linked to the Site. Although we encourage such third parties to adopt and follow their own privacy policies, we are not responsible for their collection and use of your personal information. You should refer to the privacy policies and statements of other websites or contact the respective web-masters of those websites to obtain information regarding their information collection, use and disclosure policies.</p>
      <p>When you have clicked on a third party logo or URL displayed on our Site which links you to a different website, our Privacy Policy no longer applies and you must read the privacy policy of the third party to see how your personal information will be handled on their website.</p>
      <h4>5. What choices do you have about the collection and use of your personal information?</h4>
      <h6>CONTROL OF YOUR INFORMATION</h6>
      <p>You may change or delete any information you’ve shared with us by sending us an email. We do keep records of past purchases and periodically back up information, so it may not be possible for you to delete all information we have stored, and we may retain certain information to prevent fraud and for customer and order management purposes.</p>
      <h6>OPTING OUT OF RECEIVING COMMUNICATIONS FROM US</h6>
      <p>If you no longer want to receive our newsletter, emails or other announcements, you may unsubscribe by using the unsubscribe link at the bottom of our emails.</p>
      <h4>6. What security measures do we take to safeguard your personal information?</h4>
      <p>The personal information that you provide to us is stored on servers, which are located in secured facilities with restricted access, and protected by protocols and procedures designed to ensure the security of such information. In addition, we restrict access to personal information to Crediscounts employees, independent contractors and agents who need to know this information in order to develop, operate and maintain the Crediscounts Features. All Crediscounts personnel who have access to this information are trained in the maintenance and security of such information.</p>
      <p>However, no server, computer or communications network or system, or data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect user information, we cannot ensure or warrant the security of any information you transmit to us or through the use of any of the Crediscounts Features and you acknowledge and agree that you provide such information and engage in such transmissions at your own risk. Once we receive a transmission from you, we will endeavor to maintain its security on our systems.</p>
      <h6>EUROPEAN COMMISSION’S DIRECTIVE ON DATA PROTECTION – SAFE HARBOR</h6>
      <p>Crediscounts adheres to the Safe Harbor Principles that were established by the U.S. Department of Commerce in consultation with the European Commission to comply with the European Commission’s Directive on Data Protection.</p>
      <h4>7. About this policy</h4>
      <h6>NOTIFICATION OF CHANGES</h6>
      <p>This Privacy Policy may change from time to time and we will post all changes on this page.</p>
      <h6>QUESTIONS? CONTACT US</h6>
      <p>If you have any questions about our privacy practices or this Privacy Policy, please contact us.</p>
      <p> Buyer : service@crediscounts.com</p>
      <p>Seller: support@crediscounts.com</p>
      <!-- <el-collapse v-model="activeName"
                   accordion>
        <el-collapse-item :title="conItem.title"
                          :name="conItem.name"
                          v-for="(conItem, index) in containerList"
                          :key="index">
          <div>
            <p v-for="(pItem, index) in conItem.text"
               :key="index">{{pItem.textP}}</p>
          </div>
        </el-collapse-item>
      </el-collapse> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '1'
      // 流程列表
    //   stepList: [
    //     {
    //       bool: true,
    //       name: 'Product payment release',
    //       path: [
    //         {
    //           pathD:
    //             'M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'
    //         },
    //         {
    //           pathD:
    //             'M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z'
    //         },
    //         {
    //           pathD:
    //             'M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: true,
    //       name: 'seller creates product information',
    //       path: [
    //         {
    //           pathD:
    //             'M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: true,
    //       name: 'Buyer submits request',
    //       path: [
    //         {
    //           pathD:
    //             'M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z'
    //         },
    //         {
    //           pathD:
    //             'M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: false,
    //       name: 'Get the refund to your PayPal account',
    //       path: [
    //         {
    //           pathD:
    //             'M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z'
    //         },
    //         {
    //           pathD:
    //             'M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z'
    //         }
    //       ]
    //     }
    //   ]
      // 问题列表
    //   containerList: [
    //     {
    //       name: 1,
    //       title: '1.What is crediscounts?',
    //       text: [
    //         { textP: 'crediscounts is a discount platform for Amazon products,we collect the latest deals to help buyers save money. Every user can choose the products they would like to buy and purchase them on Amazon at the lowest price' }
    //       ]
    //     },
    //     {
    //       name: 2,
    //       title: '2.How do I get discounted deals on crediscounts?',
    //       text: [
    //         { textP: 'There are 4 easy steps to get any discounts on crediscounts' },
    //         { textP: 'Step 1: Create a crediscounts buyer account and complete your information.' },
    //         { textP: 'Step 2: Apply for the transaction request and contact the seller or wait for the seller’s approval.' },
    //         { textP: 'Step 3: After the seller approves your transaction request, buy the item on Amazon and upload your order information.' },
    //         { textP: 'Step 4: Seller confirms your order information and refunds the order money to your PayPal account accordingly.' }
    //       ]
    //     },
    //     {
    //       name: 3,
    //       title: '3.Why did my transaction request fail?',
    //       text: [
    //         { textP: '1.The number of product requests has reached the limit set by the seller.' },
    //         { textP: '2.Your available points are not enough to claim the deal.' }
    //       ]
    //     },
    //     {
    //       name: 4,
    //       title: '4.Which Amazon market does crediscounts support?',
    //       text: [
    //         { textP: 'Support US, UK, DE, FR, IT, CA, JP, ES, BR, IN, MX. The United States site has the most traffic, followed by Germany, the United Kingdom, Spain, and other European countries.' }
    //       ]
    //     },
    //     {
    //       name: 5,
    //       title: '5.Does crediscounts comply with the Amazon TOS Terms of Service?',
    //       text: [
    //         { textP: 'Sure, the crediscounts is fully compliant with Amazon’s platform rules. At the same time, third-party Amazon platforms are welcomed to bring in more high-quality off-site traffic to sellers’ stores and products.' }
    //       ]
    //     },
    //     {
    //       name: 6,
    //       title: '6.When will my money be transferred to my account?',
    //       text: [
    //         { textP: 'Usually, after the seller confirms that your order is valid, you will receive a refund to your PayPal account. You can check the refund status under My Deals.' }
    //       ]
    //     },
    //     {
    //       name: 7,
    //       title: '7.Will you cover the transaction fee charged by PayPal platform?',
    //       text: [
    //         { textP: 'No, we don’t pay PayPal. Besides, the customer may pay taxes and additional shipping costs on Amazon. You can negotiate with the seller about who will bear the transaction fees!' }
    //       ]
    //     },
    //     {
    //       name: 8,
    //       title: '8.What payment methods and refund methods do crediscounts support?',
    //       text: [
    //         { textP: 'We currently only support refunds for PayPal accounts. Only a small number of merchants will refund the discount volume or gift card at the request of the buyer.' }
    //       ]
    //     },
    //     {
    //       name: 9,
    //       title: '9.Do mechants have access to my information?',
    //       text: [
    //         { textP: 'Currently, we will only reveal your PayPal account and Amazon profile to sellers after you have made a purchase and uploaded your order info.' }
    //       ]
    //     },
    //     {
    //       name: 10,
    //       title: '10.Why was my account suspended?',
    //       text: [
    //         { textP: 'The reasons why we suspend your account may be as follows:' },
    //         { textP: '1.You have submitted the Amazon order and requested a refund on our site, but you still have requested a refund on Amazon.' },
    //         { textP: '2.Register multiple accounts to earn points by fraud.If your account does not have these behaviors during use, please feel free to contact us.' }
    //       ]
    //     }
    //   ]
    }
  }
}
</script>

<style lang="less" scoped>
.sellerfaq {
  text-align: left;
  background: #f2f2f2;
  padding: 30px 15px 50px 15px;
  .shopStep {
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 30px 10px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .shopSvg {
        svg {
          color: #f93;
        }
      }
      .shopText {
        margin-left: 20px;
        font-size: 16px;
        line-height: 1.5;
      }
      .shopImg {
        margin-left: 40px;
      }
    }
  }
  .container {
    background: white;
    margin-top: 20px;
    h4 {
      font-size: 28px;
      padding: 26px 36px 10px 36px;
      color: #ff9933;
      font-weight: 500;
    }
    h6 {
      font-size: 22px;
      padding: 5px 36px;
      color: #ff9933;
      font-weight: 500;
      margin: 0;
    }
    p {
      font-size: 16px;
      padding: 10px 36px;
      margin: 0;
      font-weight: 100;
      line-height: 2;
      em{
        color:#FF4040;
      }
    }
    //  .el-collapse-item__header {
    //    padding-left: 36px;
    //  }
    .el-collapse {
      .el-collapse-item {
        padding-left: 36px;
        border-bottom: 1px solid #ebeef5;
        /deep/ .el-collapse-item__header {
          border-bottom: 0px;
          position: relative;
          padding-left: 15px;
          font-size: 16px;
          font-weight: 600;
          /deep/ .el-collapse-item__arrow {
            position: absolute;
            left: 0;
            top: 18px;
            //  transform: translateY(-50%);
          }
        }
        /deep/ .el-collapse-item__wrap {
          border-bottom: 0px;
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
